<template>
  <div class="main">
    <textarea placeholder="original" name="" id="" v-model="text"></textarea>
    <textarea
      placeholder="reversed"
      class="reversed"
      name=""
      id=""
      disabeled
      v-model="reversed"
      readonly
    ></textarea>
    <button @click="copy"><img src="clipboard.svg" alt="" /></button>
  </div>
</template>

<script>
// require("aframe");
// import Instructions from './Instructions.vue';

export default {
  name: "Scene",

  data: () => {
    return {
      text: "",
    };
  },
  computed: {
    // a computed getter
    reversed: function () {
      // `this` points to the vm instance
      return this.text.split("").reverse().join("");
    },
  },
  mounted() {},
  methods: {
    copy: function () {
      var copyText = document.querySelector(".reversed");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1,h2 {
  margin: 0;
}
.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

textarea {
  width: 100%;
  height: 100%;
  font-size: 3rem;
  padding-left: 20px;
  &.reversed {
    background: pink;
  }
}
button {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
}
img {
  width: 50px;
  height: 50px;
}
</style>
